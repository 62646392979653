
import { Component, Vue, Watch } from 'vue-property-decorator';
import ImageView from '@/components/modals/ImageView.vue';
import ReviewShare from '@/components/modals/ReviewShare.vue';
import TestimonialsSection from '@/components/Products/TestimonialsSection.vue';
import ProductGridCard from '@/components/Products/ProductGridCard.vue';
import CategorySubscription from '@/components/Products/CategorySubscription.vue';
import { dispatchGetInvoiceDetails, dispatchGetProductDetail, dispatchGetProductsByTagList, dispatchRemoveFromCart, dispatchRequestProductPurchase, dispatchToggleProductInCart, dispatchToggleProductInWishlist } from '@/store/products/actions';
import { getLocalCompareProducts, getLocalSessionId, saveLocalCompareProducts } from '@/utils';
import { readInvoiceDetail, readProductDetail, readProductsInvoice, readProductsInWishlist } from '@/store/products/getters';
import { commitSetCompareCount, commitSetProductsInvoice } from '@/store/products/mutations';
import RecentlyViewedProducts from '@/components/Products/RecentlyViewedProducts.vue';
import { readTestimonialsResponse } from '@/store/testimonials/getters';
import { IProductsList } from '@/interfaces/products';
import { commitSetCartSidebarShow } from '@/store/main/mutations';
import BuyOneClick from '@/components/modals/BuyOneClick.vue'

@Component({
  components: {
    // ReviewUnknown,
    // ReviewUser,
    ImageView,
    ReviewShare,
    RecentlyViewedProducts,
    TestimonialsSection,
    ProductGridCard,
    CategorySubscription,
    BuyOneClick
  },
})

export default class ProductView extends Vue {
  public petAge: any = null;
  public petWeight = 0;
  public imageSlide = 0;
  public packageSizeCalculation = 0;
  public calculatorWeights: any = {};
  public counterNumber = 1;
  public buyOneClickShow: boolean = false;
  public imagesModalShow = false;
  public loadingState: any = {
    page: true,
    submit: false,
  };
  public selectedParameterValues: any = {};
  public relatedProductsList: IProductsList[] = [];
  public compareList: number[] = [];
  public showFeedingCalculator: boolean = false;

  @Watch('$route.params')
  public onParamsChanged(toParams, previousParams) {
    console.log('params changed', toParams, previousParams);
    this.loadPageData();
  }

  @Watch('invoiceDetails')
  public onInvoiceDetailsChanged(toParams, previousParams) {
    setTimeout(() => {
      const count = this.quantityOfProductInCart();
      this.counterNumber = count > 0 ? count : 1;
    }, 100)
  }

  public mounted() {
    this.loadPageData();
  }

  public async loadPageData() {
    this.loadingState.page = true;
    await dispatchGetProductDetail(
      this.$store, {
        product_codename: this.$route.params.codename,
        anon_session_id: getLocalSessionId(),
      },
    );
    if (!this.productDetail) {
      this.$router.push('/home');
      return;
    }
    let selectedParameterValues: any = {};
    for (let i in this.productDetail.properties) {
      selectedParameterValues[this.productDetail.properties[i].id] = this.productDetail.properties[i].values[0].id;
    }
    this.selectedParameterValues = selectedParameterValues;

    this.loadingState.page = false;

    this.showFeedingCalculator = false;
    this.calculatorWeights = {};
    this.packageSizeCalculation = 0;
    this.petAge = null;
    this.petWeight = 0;

    if (this.productDetail.feeding_calculator && this.productDetail.feeding_calculator.ages) {
      if (this.productDetail.feeding_calculator.ages_list.length > 0) {
        this.petAge = this.productDetail.feeding_calculator.ages_list[0];
      }
      this.handleChangePetAge();
      this.packageSizeCalculation = this.productDetail.feeding_calculator.packs[0];
      this.showFeedingCalculator = true;
    }
    if (this.productDetail.breadcrumbs.length > 2) {
      this.relatedProductsList = await dispatchGetProductsByTagList(
        this.$store, {data: {category: this.productDetail.breadcrumbs[this.productDetail.breadcrumbs.length - 1].codename}, rowQuery: '?page=1'},
      );
    }
    let compareList = getLocalCompareProducts();
    this.compareList = compareList ? JSON.parse(compareList) : [];
    commitSetCompareCount(this.$store, this.compareList.length);
    const count = await this.quantityOfProductInCart();
    this.counterNumber = count > 0 ? count : 1;
  }

  public handleChangePetAge() {
    if (this.productDetail && this.productDetail.feeding_calculator && this.productDetail.feeding_calculator.ages) {
      this.calculatorWeights = this.productDetail.feeding_calculator.ages[this.petAge];
      if (this.calculatorWeights && this.calculatorWeights.weight) {
        this.petWeight = this.calculatorWeights.weight[0];
      } else {
        this.petWeight = 0;
      }
    } else {
      this.calculatorWeights = {};
      this.petWeight = 0;
    }
  }

  public async handleSelectParameterValue(parameter, newValue) {
    let newObj: any = {};
    for (const [key, value] of Object.entries(this.selectedParameterValues)) {
      if (Number(parameter) === Number(key)) {
        newObj[key] = newValue;
      } else {
        newObj[key] = value;
      }
    }
    this.selectedParameterValues = newObj;
    const count = await this.quantityOfProductInCart();
    this.counterNumber = count > 0 ? count : 1;
  }

  public isQuantityOutOfStock(parameter_id, value_id) {
    if (this.productDetail) {
      let selectedValues: any = [];
      // console.log(parameter_id, value_id)
      for (const [key, value] of Object.entries(this.selectedParameterValues)) {
        if (Number(key) !== Number(parameter_id)) {
          selectedValues.push(`${key}:${value}`);
        } else {
          selectedValues.push(`${key}:${value_id}`);
        }
      }
      // console.log(selectedValues)
      for (const [key, value] of Object.entries<any>(this.productDetail.variations)) {
        let matches = 0;
        for (let i in value.parameters) {
          for (let j in selectedValues) {
            if (value.parameters[i] === selectedValues[j]) {
              matches += 1;
            }
          }
        }
        if (matches === value.parameters.length) {
          return value.quantity_count === 0;
        }
        matches = 0;
      }
    }
    return false;
  }

  get selectedVariation(): any {
    if (this.productDetail) {
      let selectedValues: any = [];
      for (const [key, value] of Object.entries(this.selectedParameterValues)) {
        selectedValues.push(`${key}:${value}`);
      }
      for (const [key, value] of Object.entries(this.productDetail.variations)) {
        let matches = 0;
        // @ts-ignore
        for (let i in value.parameters) {
          for (let j in selectedValues) {
            // @ts-ignore
            if (value.parameters[i] === selectedValues[j]) {
              matches += 1;
            }
          }
        }
        // @ts-ignore
        if (matches === value.parameters.length) {
          // console.log(value);
          // console.log(this.selectedParameterValues);
          return value;
        }
        matches = 0;
      }
    }
    return null;
  }

  public async handleInputCountNumber() {
    if (!this.selectedVariation) {
      return null;
    }
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        // @ts-ignore
        if (this.invoiceDetails.items[i].object_id === this.selectedVariation.id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }
    if (inCart) {
      this.loadingState.submit = true;
      if (this.counterNumber && Number(this.counterNumber) && Number(this.counterNumber) > 0) {
        await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'count', quantity: Number(this.counterNumber)});
      }
      this.loadingState.submit = false;
    }
  }

  public async handleDeleteFromCart() {
    if (!this.selectedVariation) {
      return null;
    }
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        // @ts-ignore
        if (this.invoiceDetails.items[i].object_id === this.selectedVariation.id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }
    if (inCart) {
      await dispatchRemoveFromCart(this.$store, {id: inCart.id, anon_session_id: getLocalSessionId()});
      if (this.productsInvoice) {
        dispatchGetInvoiceDetails(this.$store, {invoice_id: this.productsInvoice, anon_session_id: getLocalSessionId()});
      }
      this.counterNumber = 1;
    }
  }

  public async handleAddToCart() {
    if (!this.selectedVariation) {
      return null;
    }
    // @ts-ignore
    // this.animateElement(`add-to-cart-${this.product.id}`, 'animate__bounceIn');
    this.loadingState.submit = true;
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        // @ts-ignore
        if (this.invoiceDetails.items[i].object_id === this.selectedVariation.id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }

    if (inCart) {
      this.counterPlusFunction();
      await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'add'});
    } else {
      const response = await dispatchRequestProductPurchase(
        this.$store, {
          // @ts-ignore
          product_property: this.selectedVariation.id,
          quantity: this.counterNumber,
          invoice: this.productsInvoice ? this.productsInvoice : null,
          anon_session_id: getLocalSessionId(),
        },
      );
      commitSetProductsInvoice(this.$store, response);
      if (this.productsInvoice) {
        await dispatchGetInvoiceDetails(this.$store, {invoice_id: this.productsInvoice, anon_session_id: getLocalSessionId(),});
      }
      commitSetCartSidebarShow(this.$store, true);
    }
    this.loadingState.submit = false;
    // @ts-ignore
    // this.animateElement(`added-to-cart-amount-${this.product.id}`, 'animate__jello');
  }

  public async handleRemoveFromCart() {
    if (!this.selectedVariation) {
      return null;
    }
    // @ts-ignore
    // this.animateElement(`remove-to-cart-${this.product.id}`, 'animate__bounceIn');
    this.loadingState.submit = true;
    if (this.invoiceDetails && this.productDetail) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          // @ts-ignore
          if (this.invoiceDetails.items[i].object_id === this.selectedVariation.id && this.invoiceDetails.items[i].quantity > 1) {
            await dispatchToggleProductInCart(
              this.$store, {invoice_item: this.invoiceDetails.items[i].id, action: 'remove'},
            );
            this.counterMinusFunction();
          }
        }
      }
    }
    this.loadingState.submit = false;
    // @ts-ignore
    // this.animateElement(`added-to-cart-amount-${this.product.id}`, 'animate__jello');
  }

  public quantityOfProductInCart() {
    if (!this.selectedVariation) {
      return 0;
    }
    if (this.invoiceDetails && this.productDetail) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          // @ts-ignore
          if (this.invoiceDetails.items[i].object_id === this.selectedVariation.id) {
            return this.invoiceDetails.items[i].quantity;
          }
        }
      }
    }
    return 0;
  }

  public counterPlusFunction(){
    // if (this.counterNumber <= 3) {
    //     this.counterNumber++;
    // }
    this.counterNumber++;
  }
  public counterMinusFunction(){
    if (this.counterNumber >= 2) {
        this.counterNumber--;
    }
  }

  public async toggleProductInWishlist() {
    if (!this.productDetail) {
      return;
    }
    if (this.productsInWishlist.includes(this.productDetail.id)) {
      await dispatchToggleProductInWishlist(
        this.$store, {product_id: this.productDetail.id, action: 'remove', anon_session_id: getLocalSessionId()},
      );
    } else {
      await dispatchToggleProductInWishlist(
        this.$store, {product_id: this.productDetail.id, action: 'add', anon_session_id: getLocalSessionId()},
      );
    }
  }

  public async toggleBuyInOneClick() {
  }

  public async toggleProductInCompareList() {
    if (!this.selectedVariation) {
      return;
    }
    if (this.compareList.includes(this.selectedVariation.id)) {
      let compareList = this.compareList;
      const index = compareList.indexOf(this.selectedVariation.id);
      if (index > -1) {
          compareList.splice(index, 1);
      }
      saveLocalCompareProducts(JSON.stringify(compareList));
    } else {
      let compareList = this.compareList;
      compareList.push(this.selectedVariation.id);
      saveLocalCompareProducts(JSON.stringify(compareList));
    }
    let compareList = getLocalCompareProducts();
    this.compareList = compareList ? JSON.parse(compareList) : [];
    commitSetCompareCount(this.$store, this.compareList.length);
  }

  get productsInWishlist() {
    return readProductsInWishlist(this.$store);
  }

  get totalPrice() {
    if (this.selectedVariation) {
      // let price = this.productDetail.price_promo && this.productDetail.price != this.productDetail.price_promo ? this.productDetail.price_promo : this.productDetail.price;
      // @ts-ignore
      let price = this.selectedVariation.price_for_user;
      return price * this.counterNumber;
    }
    return 0;
  }

  get totalCleanPrice() {
    if (this.selectedVariation) {
      // @ts-ignore
      let price = this.selectedVariation.price_for_user_clean;
      return price * this.counterNumber;
    }
    return 0;
  }

  get dailyAmountCalculation() {
    if (this.petWeight && this.calculatorWeights.weight) {
      const index = this.calculatorWeights.weight.indexOf(this.petWeight);
      if (index > -1) {
        return this.calculatorWeights.nutrition_gramm[index];
      }
    }
    return 0;
  }

  get productDetail() {
    return readProductDetail(this.$store);
  }

  get invoiceDetails() {
    return readInvoiceDetail(this.$store);
  }

  get productsInvoice() {
    return readProductsInvoice(this.$store);
  }
  
  get testimonialsResponse() {
    return readTestimonialsResponse(this.$store);
  }

  get imagesList() {
    if (this.selectedVariation && this.selectedVariation.gallery_images.length > 0) {
      return this.selectedVariation.gallery_images
    } else if (this.productDetail?.main_image) {
      return [this.productDetail.main_image]
    }
    return []
  }
}


import { UserProductViewHistory } from '@/interfaces/products';
import { dispatchGetProductsViewHistoryList } from '@/store/products/actions';
import { getLocalSessionId } from '@/utils';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ProductGridCard from '@/components/Products/ProductGridCard.vue';

@Component({
    components: {
      ProductGridCard,
    },
})

export default class RecentlyViewedProducts extends Vue {
  @Prop({required: false, default: 'letest-view-section'}) public rootClass!: string;
  @Prop({required: false, default: 'title-with-show-all-btn m-b-55'}) public titleClass!: string;
  @Prop({required: false, default: 'letest-view-section-row'}) public cardsClass!: string;

  public recentlyViewedProductsList: UserProductViewHistory[] = [];

  public async mounted() {
    this.recentlyViewedProductsList = await dispatchGetProductsViewHistoryList(
      this.$store, {anon_session_id: getLocalSessionId(),},
    );
  }
}
